export default {
    methods: {
        handleError(err) {
            console.log(err)
            if (err instanceof Error && err.message.includes('status code 401')) {
                this.$message.error('登录过期请重新登录')
                this.$router.replace('/login')
            } else {
                const msg = typeof err === 'string' ? err : JSON.stringify(err)
                this.$message.error(msg)
            }
        }
    }
}
