import Vue from 'vue'
import VueRouter from 'vue-router'

const Login = () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
const Home = () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
const UserList = () => import(/* webpackChunkName: "userList" */ '../views/UserList.vue')
const CompanyList = () => import(/* webpackChunkName: "companyList" */ '../views/CompanyList.vue')
const DeviceList = () => import(/* webpackChunkName: "warningList" */ '../views/DeviceList.vue')
const WarningList = () => import(/* webpackChunkName: "warningList" */ '../views/WarningList.vue')
const Welcome = () => import(/* webpackChunkName: "Welcome" */ '../views/Welcome.vue')
const WarningTrade = () => import(/* webpackChunkName: "warningTrade" */ '../views/WarningTrade.vue')
const NotFound = () => import(/* webpackChunkName: "notFound" */ '../views/NotFound.vue')

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/home',
        name: 'home',
        component: Home,
        children: [
            {
                path: '/',
                component: Welcome
            },
            {
                path: 'companyList',
                component: CompanyList
            },
            {
                path: 'userList',
                component: UserList
            },
            {
                path: 'welcome',
                component: Welcome
            },
            {
                path: 'warningList',
                component: WarningList
            },
            {
                path: 'warningTrade',
                component: WarningTrade
            },
            {
                path: 'deviceList',
                component: DeviceList
            },
            {
                path: '*',
                redirect: 'welcome'
            }
        ]
    },
    {
        path: '*',
        component: NotFound
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
